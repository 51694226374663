import {SectionContainer, SectionPreTitle, SectionTitle} from "../../components/Shared"
import {Layout} from "../../components/Layout"
import React, {ChangeEvent, useEffect, useState} from "react"
import {fetchAllSystems, ScrapedGameData, searchGames} from "../../api/scrapApi"
import {Card, FormControl, Grid, InputLabel, MenuItem, Select, TextField} from "@material-ui/core"
import {useI18next} from "gatsby-plugin-react-i18next"
import {GameCard} from "../../components/GameCard"
import styled from "@emotion/styled"
import {useLocation} from "@reach/router"
import queryString from "query-string"


const PaddedCard = styled(Card)(({theme}) => ({
  padding: theme.spacings.sm,
  marginTop: theme.spacings.md,
  marginBottom: theme.spacings.md
}))
const Marged = styled.span(({theme}) => ({
  marginRight: theme.spacings.md,
}))

export const GamesPage = () => {
  const [games, setGames] = useState<ScrapedGameData[]>([])
  const [systems, setSystems] = useState<{ id: string, name: string }[]>([])
  const [searchCriteria, setSearchCriteria] = useState({systemId: "*", fts: ""})
  const i18n = useI18next()
  useEffect(() => {
    searchGames(searchCriteria.systemId, searchCriteria.fts).then(
      (result) => {
        setGames(result.hits.hits.map((_) => _._source))
      },
      (error) => {
      }
    )
  }, [searchCriteria])

  const location = useLocation()
  useEffect(() => {
    fetchAllSystems().then((result) => {
        setSystems([{id: "*", name: "All"}].concat(result.hits.hits.map(_ => ({
          name: _._source.noms.nom_eu,
          id: `${_._source.id}`
        }))))
      },
      (error) => {
      }
    )
    const query = queryString.parse(location.search)
    if (query.system && typeof query.system === "string") {
      setSearchCriteria({...searchCriteria, systemId: query.system})
    }
  }, [])

  const updateSearch = (event: ChangeEvent<{ name?: string; value: unknown }>) => {
    setSearchCriteria({...searchCriteria, [event.target.name!]: event.target.value})
  }
  return (
    <Layout title={"Recalbox Game Database"}>
      <SectionContainer>
        <SectionPreTitle>What are you searching ?</SectionPreTitle>
        <SectionTitle>All Recalbox compatible games!</SectionTitle>
        <PaddedCard>
          <Marged>
            <FormControl>
              <InputLabel id="system">System</InputLabel>
              <Select
                labelId="system"
                name="systemId"
                value={systems.find((_) => _.id == searchCriteria.systemId)?.id || "*"}
                onChange={updateSearch}
              >
                {systems.map((system, i) => {
                  return <MenuItem value={system.id}>{system.name}</MenuItem>
                })}
              </Select>
            </FormControl>
          </Marged>
          <Marged>
            <TextField
              label="Full text search"
              placeholder="Aladdin Super Nes"
              variant="standard"
              name={"fts"}
              InputLabelProps={{
                shrink: true,
              }}
              value={searchCriteria.fts}
              onChange={updateSearch}
            />
          </Marged>
        </PaddedCard>

        <Grid container spacing={4}>
          {games.map((game) => {
            return (
              <Grid item xs={12} sm={4} lg={3}>
                <GameCard key={game.id} game={game} currentLang={i18n.language}></GameCard>
              </Grid>
            )
          })}
        </Grid>
      </SectionContainer>
    </Layout>
  )
}
